import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "@/components/layout"
import Portfolio from "@/components/portfolio"
import styled from "@emotion/styled"

const Wrap = styled.div`
  margin: 0 auto;
  width: 800px;
  max-width: 100%;
  font-size: 22px;

  h1 {
    font-weight: 300;
  }
`

export default function Header() {
  return (
    <Layout currentPage="about">
      <Wrap>
        <h1>Hi.</h1>
        <p>
          My name is Igor Husar and I am a Bratislava-based freelance video
          editor with experience in color correction and eye-catching motion
          graphics.
        </p>

        <p>
          I have a master’s degree in communication & mass media studies at the
          University of Pavol Jozef Safarik in Kosice. The university gave me
          invaluable firsthand knowledge from industry experts from every medium
          ranging from television and radio to print.
        </p>

        <p>
          Throughout my study I found my love for digital games and film in both
          laic and academic points of view with my bachelor’s thesis (Nonverbal
          communication in digital games) and as well with my diploma thesis
          (Film adaptation of digital games).
        </p>

        <p>
          Feel free to contact me at:{" "}
          <a href="mailto:husar.igor@gmail.com">husar.igor@gmail.com</a>
        </p>
      </Wrap>
    </Layout>
  )
}
